import React, {useRef} from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';



function Footer() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_dqv2snn', 'template_retcpps', form.current, 'OWP9J1uJvbHwNJoOL')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Contact us below with your information and which location your interested in! Remember to include your contact information.
        </p>        
        <div className='input-areas'>
          {/* 
          <form ref={form} onSubmit={sendEmail}>
            <p>
            <label>Name</label>
            <input type="text" name="user_name" />
            </p>
            <p>
            <label>Email</label>
            <input type="email" name="user_email" />
            </p>
            <p>
            <label>Message</label>
            <textarea name="message" />
            </p>
            <p>
            <input type="submit" value="Send" />
            </p>
          </form>
          */}

          <div id="form-main">
            <div id="form-div">
              <form class="form" id="form1" ref={form} onSubmit={sendEmail}>
                
                <p class="name">
                  <input name="user_name" type="text" class="validate[required,custom[onlyLetter],length[0,100]] feedback-input" placeholder="Name" id="name" />
                </p>
                
                <p class="email">
                  <input name="user_email" type="text" class="validate[required,custom[email]] feedback-input" id="email" placeholder="Email" />
                </p>
                
                <p class="text">
                  <textarea name="message" class="validate[required,length[6,300]] feedback-input" id="comment" placeholder="Comment"></textarea>
                </p>
                
                
                <div class="submit">
                  <input type="submit" value="SEND" id="button-blue"/>
                  <div class="ease"></div>
                </div>
              </form>
            </div>
            </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
