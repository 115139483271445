import React from 'react';
import '../Cards.css';
import PhotoItem from '../PhotoItem';
import Footer from '../Footer';

export default function Dufferin() {
  return(
    <div>
      <h1 className='dufferin'>370 DUFFERIN STREET</h1>
      
      <div  className='cards__container'>
      <div className='cards__title'> 
        <p>UPSTAIRS APARTMENT</p>
        <p style={{color:'red'}}>No Vacancy</p>
      </div>
      <div className='cards__info'> 
        <p>Stay at the upstairs apartment portion of this two dwelling home. The unit is a fully furnished three bedroom with a kitchen, living space, and full bathroom! Free laundry in basement. Utilities and Wifi Included.</p>
      </div>
      <div className='cards__wrapper'>        
        <ul className='cards__items'>
        <PhotoItem
                src='images/DufferinUpKitchen.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Kitchen'
                path='/images/DufferinUpKitchen.jpg'
              />   
        <PhotoItem
                src='images/DufferinUpKitchen2.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Kitchen'
                path='/images/DufferinUpKitchen2.jpg'
              /> 
        <PhotoItem
                src='images/DufferinUpLivingSpace.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Living Room'
                path='/images/DufferinUpLivingSpace.jpg'
              />                          
        </ul>
        <ul className='cards__items'>
        <PhotoItem
                src='images/DufferinUpLivingSpace2.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Living Room'
                path='/images/DufferinUpLivingSpace2.jpg'
              />   
        <PhotoItem
                src='images/DufferinUpRoomA.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bedroom A'
                path='/images/DufferinUpRoomA.jpg'
              /> 
        <PhotoItem
                src='images/DufferinUpRoomB.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bedroom B'
                path='/images/DufferinUpRoomB.jpg'
              />                          
        </ul>
        <ul className='cards__items'>
        <PhotoItem
                src='images/DufferinUpRoomC.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bedroom C'
                path='/images/DufferinUpRoomC.jpg'
              />   
        <PhotoItem
                src='images/DufferinUpBathroom.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bathroom'
                path='/images/DufferinUpBathroom.jpg'
              />                          
        </ul>
      </div>
      <div className='cards__title'> 
        <p>MAIN FLOOR APARTMENT</p>
        <p style={{color:'red'}}>No Vacancy</p>
      </div>
      <div className='cards__info'> 
        <p>Stay at the main floor apartment portion of this two dwelling home. The unit is a fully furnished multi entrance two bedroom with a kitchen, living space, full bathroom and secondary half bathroom! Free laundry in basement. Utilities and Wifi Included.</p>
      </div>
      <div className='cards__wrapper'>        
        <ul className='cards__items'>
        <PhotoItem
                src='images/DufferinMainBath.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Main Bathroom'
                path='/images/DufferinMainBath.jpg'
              />   
        <PhotoItem
                src='images/DufferinMainHalfBath.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Half Bath'
                path='/images/DufferinMainHalfBath.jpg'
              /> 
        <PhotoItem
                src='images/DufferinMainKitchen.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Kitchen'
                path='/images/DufferinMainKitchen.jpg'
              />                          
        </ul>
        <ul className='cards__items'>
        <PhotoItem
                src='images/DufferinMainLiving.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Living Room'
                path='/images/DufferinMainLiving.jpg'
              />   
        <PhotoItem
                src='images/DufferinMainRoomA.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bedroom A'
                path='/images/DufferinMainRoomA.jpg'
              /> 
        <PhotoItem
                src='images/DufferinMainRoomB.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bedroom B'
                path='/images/DufferinMainRoomB.jpg'
              />
        </ul>
        <ul className='cards__items'>      
        <PhotoItem
                src='images/DufferinMainRoomB2.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bedroom B'
                path='/images/DufferinMainRoomB2.jpg'
              />                                          
        </ul>                            
      </div>
      </div>
      <Footer />  
    </div>
    
  );
}