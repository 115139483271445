import React from 'react';
import '../Cards.css';
import PhotoItem from '../PhotoItem';
import Footer from '../Footer';

export default function John() {
  return (
    <div>
      <h1 className='john'>509 JOHN Street</h1>
      
      <div  className='cards__container'>
      <div className='cards__title'> 
        <p>APARTMENT 1</p>
        <p style={{color:'red'}}>No Vacancy</p>
      </div>
      
      <div className='cards__wrapper'>        
        <ul className='cards__items'>
        <PhotoItem
                src='images/John/Apt1/apt1bath.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bathroom'
                path='/images/John/Apt1/apt1bath.jpg'
              />   
        <PhotoItem
                src='images/John/Apt1/apt 1 kitchen table.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Kitchen'
                path='/images/John/Apt1/apt 1 kitchen table.jpg'
              /> 
        <PhotoItem
                src='images/John/Apt1/apt 1 kitchen.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Kitchen'
                path='/images/John/Apt1/apt 1 kitchen.jpg'
              />                          
        </ul>
        <ul className='cards__items'>
        <PhotoItem
                src='images/John/Apt1/apt 1 Living room.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Living Room'
                path='/images/John/Apt1/apt 1 Living room.jpg'
              />   
        <PhotoItem
                src='images/John/Apt1/apt 1 room a.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bedroom A'
                path='/images/John/Apt1/apt 1 room a.jpg'
              /> 
        <PhotoItem
                src='images/John/Apt1/apt 1 room b.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bedroom B'
                path='/images/John/Apt1/apt 1 room b.jpg'
              />                          
        </ul>        
      </div>
      <div className='cards__title'> 
        <p>APARTMENT 2</p>
        <p style={{color:'red'}}>No Vacancy</p>
      </div>      
      <div className='cards__wrapper'>        
        <ul className='cards__items'>
        <PhotoItem
                src='images/John/Apt2/Entrance.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Entrance'
                path='/images/John/Apt2/Entrance.jpg'
              />   
        <PhotoItem
                src='images/John/Apt2/Entrance2.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Entrance 2'
                path='/images/John/Apt2/Entrance2.jpg'
              /> 
        <PhotoItem
                src='images/John/Apt2/Kitchen.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Kitchen'
                path='/images/John/Apt2/Kitchen.jpg'
              />                          
        </ul>                                   
      </div>
      <div className='cards__title'> 
        <p>APARTMENT 4</p>
        <p style={{color:'red'}}>No Vacancy</p>
      </div>      
      <div className='cards__wrapper'>        
        <ul className='cards__items'>
        <PhotoItem
                src='images/John/Apt4/Bathroom.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bathroom'
                path='/images/John/Apt4/Bathroom.jpg'
              />   
        <PhotoItem
                src='images/John/Apt4/Bathroom2.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bathroom'
                path='/images/John/Apt4/Bathroom2.jpg'
              /> 
        <PhotoItem
                src='images/John/Apt4/Bedroom.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bedroom'
                path='/images/John/Apt4/Bedroom.jpg'
              />                          
        </ul>
        <ul className='cards__items'>
        <PhotoItem
                src='images/John/Apt4/Kitchen.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Kitchen'
                path='/images/John/Apt4/Kitchen.jpg'
              />   
        <PhotoItem
                src='images/John/Apt4/Kitchen2.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Kitchen'
                path='/images/John/Apt4/Kitchen2.jpg'
              /> 
        <PhotoItem
                src='images/John/Apt4/LivingRoom.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Living Room'
                path='/images/John/Apt4/LivingRoom.jpg'
              />                          
        </ul> 
        <ul className='cards__items'>
        <PhotoItem
                src='images/John/Apt4/LivingRoom3.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Living Room'
                path='/images/John/Apt4/LivingRoom3.jpg'
              />            
        <PhotoItem
                src='images/John/Apt4/LivingRoom2.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Living Room'
                path='/images/John/Apt4/LivingRoom2.jpg'
              />                          
        </ul>                                        
      </div>
      <div className='cards__title'> 
        <p>APARTMENT 5</p>
        <p style={{color:'red'}}>No Vacancy</p>
      </div>      
      <div className='cards__wrapper'>        
        <ul className='cards__items'>
        <PhotoItem
                src='images/John/Apt5/bathroom.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bathroom'
                path='/images/John/Apt5/bathroom.jpg'
              />   
        <PhotoItem
                src='images/John/Apt5/bedroomA.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bedroom'
                path='/images/John/Apt5/bedroomA.jpg'
              /> 
        <PhotoItem
                src='images/John/Apt5/bedroomB.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bedroom'
                path='/images/John/Apt5/bedroomB.jpg'
              />                          
        </ul>
        <ul className='cards__items'>
        <PhotoItem
                src='images/John/Apt5/bedroomC.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Bedroom'
                path='/images/John/Apt5/bedroomC.jpg'
              />   
        <PhotoItem
                src='images/John/Apt5/Kitchen.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Kitchen'
                path='/images/John/Apt5/Kitchen.jpg'
              /> 
        <PhotoItem
                src='images/John/Apt5/LivingRoom.jpg'
                text='Stay at a wonderful house in Downtown Thunder Bay'
                label='Living Room'
                path='/images/John/Apt5/LivingRoom.jpg'
              />                          
        </ul>                                               
      </div>
      </div>
      <Footer />  
    </div>

  );
}
