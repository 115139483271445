import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Check out these AWESOME Rentals!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/DufferinFront.jpg'
              text='Stay at a wonderful house in Downtown Thunder Bay - No Current Vacancy'
              label='370 Dufferin Street'
              path='/dufferin'
            />
            <CardItem
              src='images/JohnStreetFront.jpg'
              text='Live at one of these Luxurious Apartments - No Current Vacancy'
              label='509 John Street'
              path='/john'
            />
          </ul>          
        </div>
      </div>
    </div>
  );
}

export default Cards;
