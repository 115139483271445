import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import Dufferin from './components/pages/Dufferin';
import John from './components/pages/John';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Routes>
      <Route path='/' exact element={ <Home />}></Route>
      <Route path='/dufferin' exact element={ <Dufferin />}></Route>
      <Route path='/john' exact element={ <John />}></Route>
      </Routes>
    </Router>
    </>
  );
}

export default App;
